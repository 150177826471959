import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { HOME_TESTIMONIALS } from '../../data/home/testimonials';
import { AB_TESTING } from '../../data/site-data';

function Testimonials({
  title = 'Testimonials',
  testimonialList = HOME_TESTIMONIALS,
  variant = AB_TESTING.original,
}) {
  const [activeIndex, setActiveIndex] = useState('01');
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const swiperRef = useRef(null);

  SwiperCore.use([Navigation]);

  const onBeforeInit = (Swiper) => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const { navigation } = Swiper.params;
      navigation.prevEl = navigationPrevRef.current;
      navigation.nextEl = navigationNextRef.current;
    }
  };

  return (
    <div
      className={classNames('section-pale pt-5 pb-5 pt-md-10 pb-md-10', {
        [variant]: true,
      })}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-8">
          <h2 className="text-mega text-grey">{title}</h2>
          <div className="js-number-reviews">
            <p className="text-mega text-grey">
              <span className="js-number-reviews-current">{activeIndex}</span>/{' '}
              <span className="text-review-number js-number-reviews-all">
                {testimonialList.length < 10
                  ? `0${testimonialList.length}`
                  : testimonialList.length}
              </span>
            </p>
          </div>
        </div>
        <div className="container-white-border">
          <Swiper
            onBeforeInit={onBeforeInit}
            className="swiper-container swiper-reviews"
            ref={swiperRef}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSlideChange={(swiper) => {
              setActiveIndex(
                swiper.activeIndex + 1 < 10
                  ? `0${swiper.activeIndex + 1}`
                  : swiper.activeIndex + 1,
              );
            }}>
            <div className="swiper-wrapper">
              {testimonialList
                .sort((a, b) => b.text.length - a.text.length)
                .map(({ text, name }, index) => (
                  <SwiperSlide key={index}>
                    <p className="text-lead mb-5 mb-md-6">&ldquo;{text}&rdquo;</p>
                    <p className="text-body">- {name}</p>
                  </SwiperSlide>
                ))}
            </div>
            <div className="swiper-buttons-container">
              <div className="swiper-button-prev" ref={navigationPrevRef} />
              <div className="swiper-button-next ml-5" ref={navigationNextRef} />
            </div>
            <div className="d-flex justify-content-end">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Pandev+Law/@32.779684,-79.9334468,17z/data=!4m7!3m6!1s0x88fe7a121d60cfc1:0x5e811a74d5fdf2f3!8m2!3d32.7796795!4d-79.9312581!9m1!1b1?hl=en-US">
                <StaticImage
                  className="lazy"
                  width={289}
                  layout="fixed"
                  src="../../assets/img/google-rating.png"
                  alt="Google 5-star rating"
                />
              </a>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

Testimonials.propTypes = {
  title: PropTypes.string,
  testimonialList: PropTypes.array,
  variant: PropTypes.string,
};

export default Testimonials;
